import React, { useEffect, useState, useRef, useContext } from "react";
import styles from "./Account.module.css";
import { AuthContext } from "../../../auth/AuthProvider";
//import { ToastContext } from "../../../layout";
import { useNavigate } from "react-router-dom";

import { BiLogOutCircle } from "react-icons/bi";
import { useOnClickOutside } from "../../../hooks";

export default function Account({ show, close }) {
  const {
    keycloak
  } = useContext(AuthContext);

  const [hasRendered, setHasRendered] = useState(false);
  const ref = useRef(null);

  //const { notifyInfo, dismiss, delay } = useContext(ToastContext);

  const navigate = useNavigate();
  useOnClickOutside(ref, () => show && close());

  // useEffect(() => {
  //   if (show) {
  //     dismiss();
  //     setHasRendered(true);
  //   }
  // }, [show]);

  function AccountItem({ name, email }) {
    return (
      <li className={styles.accountItem}>
        <span>{name}</span>
        <a>{email}</a>
      </li>
    );
  }

  function Item({ id, onClick, leftIcon, rightIcon, children }) {
    return (
      <a id={id} className={styles.item} onClick={onClick}>
        <span>{leftIcon}</span>
        {children}
        <span>{rightIcon}</span>
      </a>
    );
  }

  return (
    <>
      <div
        ref={ref}
        className={`${styles.dropdown} ${show ? styles.open : ""} ${
          hasRendered ? "" : styles.preventAnimation
        }`}
      >
        <ul className={styles.list}>
          <AccountItem name={`${keycloak.tokenParsed?.name}`} email={keycloak.tokenParsed?.email} />
          <Item
            id="logoff-button"
            leftIcon={<BiLogOutCircle />}
            onClick={() => {
                navigate("/");
                keycloak.logout()
            }}
          >
            Log Off
          </Item>
        </ul>
      </div>
    </>
  );
}
