import React, { useState, useContext } from "react";
import styles from "./AppStreamButton.module.css";

const AppStreamButton = ({ application, width }) => {

  const launchAppStream = () => {
    window.open("https://auth.readyone.training/realms/master/protocol/saml/clients/appstream")
  };

  return (
    
        <button className={styles.button} onClick={() => launchAppStream()}>
          <div className={styles.selector}>
            <img
              style={{
                width: width,
              }}
              src={application.image}
              className="request-shadow"
            />
          </div>
          <span>{application.title}</span>
        </button>
      
  );
};

export default AppStreamButton;
