import React, { useState } from "react";
import styles from "./Footer.module.css";

export default function Footer() {
  const [show, setShow] = useState(false);

  return (
    <div className={styles.footer}>
      <div className={styles.feedback}>
      </div>
    </div>
  );
}
