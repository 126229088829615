import React, { useState, useEffect, createContext } from 'react';
import keycloak from './Keycloak';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [keycloakInstance, setKeycloakInstance] = useState(null);

  useEffect(() => {
    const isLocalhost = window.location.hostname === 'localhost';
    const redirectUri = isLocalhost
      ? 'http://localhost:3000' // Local development redirect URI
      : 'https://readyone.training'; // Production redirect URI

    keycloak
      .init({ onLoad: 'login-required',
        redirectUri: redirectUri }) // Use 'login-required' or 'check-sso'
      .then(authenticated => {
        setAuthenticated(authenticated);
        setKeycloakInstance(keycloak);
      })
      .catch(error => console.error("Keycloak initialization failed", error));
  }, []);

  if (!keycloakInstance) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ keycloak, authenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
