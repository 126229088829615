import React, { useState, useContext } from "react";
import { VscAccount } from "react-icons/vsc";
import styles from "./Navbar.module.css";
import { Account } from "./NavItems";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../auth/AuthProvider";
//import {ToastContext} from "../../layout";

export default function Navbar(props) {
  const [path, setPath] = useState("/Applications");
  const [accountOpen, setAccountOpen] = useState(false);
  const { keycloak } = useContext(AuthContext);
  //const {notifyInfo, delay} = useContext(ToastContext);
  const navigate = useNavigate();

  const tabs = [
    {
      route: "/Applications",
      label: "Applications",
    }
  ];
  const getActiveClass = (name) => (name === path ? styles.active : "");

  return (
    <nav className={styles.navbar}>
      <span className={styles.brandLogo}>ReadyOne<sup className={styles.trademark}>TM</sup></span>
          <ul className={styles.navLinks}>
            {tabs.map((tab) => (
              <Link to={tab.route} onClick={() => setPath(tab.route)}>
                <li
                  className={`${styles.navLink} ${getActiveClass(tab.route)}`}
                >
                  <a className={styles.navText}>{tab.label}</a>
                </li>
              </Link>
            ))}
          </ul>
          <ul className={styles.account}>
            <li>
              <span>{`Welcome, ${keycloak.tokenParsed?.given_name}!`}</span>
            </li>
            <li>
              <VscAccount onClick={() => setAccountOpen(!accountOpen)} />
              <Account
                show={accountOpen}
                close={() => {
                  setTimeout(() => setAccountOpen(false), 200);
                }}
              ></Account>
            </li>
          </ul>
    </nav>
  );
}
