import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoute from './auth/PrivateRoute';
import Applications from './pages/Applications/Applications';
import Login from './pages/Login/Login';
import { Layout } from "./layout";

function App() {
  return (
    <Router>
      <Layout>
      <Routes>
      
        <Route path="/login" component={Login} />
        
        <Route 
          path="*" 
          element={
            <PrivateRoute>
              <Applications />
            </PrivateRoute>
          } 
        />
        
      </Routes>
      </Layout>
    </Router>
  );
}

export default App;
