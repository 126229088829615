import React from "react";
import styles from "./Applications.module.css";
import { Title, Footer, Video } from "./Layout";
import { AppDrawer } from "./Apps";

const Applications = () => {

  return (
    <>
      <div
        className={styles.gettingStartedWholeBox}
      >
        <Title
          title="ReadyOne Training"
          subtitle="Select an application to get started"
        />

        <AppDrawer />
        <AppDrawer />
        
      </div>
    </>
  );
};

export default Applications;
