const apiURL = "https://api.readyone.net";
const apiKey = "byGTHF1wmJ1nxf7eMp7fNytA8M9PWwLsIU85or90";

export function getHowTo_old() {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${apiURL}/gethowto`, requestOptions)
    .then((response) => response.json())
    .then((body) => body.body);
}

export function getTable(tableName) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({ tableName: tableName });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/getTable`, requestOptions).then((response) =>
    response.json()
  );
}

export function sendEmail(Data) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(Data);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/sendEmail`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
}

export function getHowTo() {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  // myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${apiURL}/gets3structure`, requestOptions)
    .then((response) => response.json())
    .then((body) => body.body);
}

export function search(searchTerm) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${apiURL}/search?q=${searchTerm}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result.hits.hits)
    .catch((error) => console.log("error", error));
}

export function searchWorkItems(searchTerm) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${apiURL}/search/work-items?q=${searchTerm}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result.hits.hits)
    .catch((error) => console.log("error", error));
}

export function submitFeedback(feedback) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(feedback);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/submit-feedback`, requestOptions).then((response) =>
    response.json()
  );
}

export function addUser(user) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", "QTONptmuas7y9K7bnenki6Qs7mClbJfb2iPC9Ruh");
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(user);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(
    "https://api.admin.readyone.net/add/hol-user",
    requestOptions
  ).then((response) => response.json());
}

export function addMoreUsers(user) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", "QTONptmuas7y9K7bnenki6Qs7mClbJfb2iPC9Ruh");
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(user);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(
    "https://api.admin.readyone.net/add/hol-user",
    requestOptions
  ).then((response) => response.json());
}

export function createIntake(intake) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", "QTONptmuas7y9K7bnenki6Qs7mClbJfb2iPC9Ruh");
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(intake);
  console.log(intake);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch("https://api.admin.readyone.net/set/hol-intake", requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
}

export function getUserApps(id) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({ id, environment: "ReadyOne_UserApps" });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/get-user-apps`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
}

export function updateUserApps(id, userApps) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    id,
    appList: userApps,
    environment: "ReadyOne_UserApps",
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/update-user-apps`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
}


export function generateIntakeForm(id, notify) {
  var myHeaders = new Headers();
myHeaders.append("X-Api-Key", apiKey);
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({
  id,
  notify
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

return fetch(`${apiURL}/hol-intake-generator`, requestOptions)
  .then(response => response.json())
  .catch(error => console.log('error', error));
}

export function getIntakeForm(id) {
  var myHeaders = new Headers();
myHeaders.append("X-Api-Key",apiKey);
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({
  id
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

return fetch(`${apiURL}/get-hol-intake`, requestOptions)
  .then(response => response.json()).then(response => JSON.parse(response.body))
  .catch(error => console.log('error', error));
}

export function updateIntakeForm(id, intake) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", 'QTONptmuas7y9K7bnenki6Qs7mClbJfb2iPC9Ruh');
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    id,
   intake
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`https://api.admin.readyone.net/update/hol-intake`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
}