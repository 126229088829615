import React, { useState, useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from '../../auth/AuthProvider';
import styles from "./Login.module.css";
import saicLogo from '../../images/saiclogo.png';

const Login = () => {
  const [show, setShow] = useState(false);
  const { keycloak, authenticated } = useContext(AuthContext);

  const login = () => {
    keycloak.login();
  };

  const logout = () => {
    keycloak.logout();
  };

  return (
    <div className={styles.loginWholePage}>
      <div className={styles.loginModal} show={true} centered>
        <div className={styles.loginModalLogo}>
        <img src={saicLogo} alt="SAIC Logo" />
        </div>

        <div className={styles.loginModalRo}>
          <div className={styles.brandLogo}>
            ReadyOne<div className={styles.trademark}>TM</div>
          </div>
        </div>

        <div className={styles.loginModalHeader}>
          <div>
            <div className={styles.loginMessage}>Please login to continue.</div>
          </div>
        </div>

        <div className={styles.loginModalFooter}>
          <button
            className={styles.loginModalButton}
            onClick={() => {}}
          >
            Login
          </button>
        </div>

        <div className={styles.loginModalFooterDisc}>
          <button className={styles.discButton} onClick={() => setShow(!show)}>
            <div className={styles.loginDiscText} id="disclaimer">
              By accessing this site, you are agreeing to SAIC's Acceptable Use
              Policy.
              <br />
              Click to learn more.
              {show ? (
                <div className={styles.policyButton}>
                  This computer system is the property of SAIC and includes all
                  related equipment, networks, and network devices. This
                  computer system may contain US government information and is
                  only for use by authorized users for business purposes. There
                  is no right of privacy in this computer system. Use
                  constitutes consent and acknowledgement that the computer
                  system, and any information placed or sent over the computer
                  system, is monitored, recorded and subject to audit and
                  collection for all lawful purposes including in relation to
                  investigations and/or legal proceedings. Any unauthorized use,
                  accessing of or attempt to access this computer system, or any
                  unauthorized alteration, damage, removing, addition, or
                  destruction of any data, program or software in this computer
                  system is subject to criminal and civil prosecution under
                  state and/or federal law. Unauthorized use may result in the
                  delivery of possible evidence collected during monitoring to
                  law enforcement officials. Failure to comply with this notice
                  may be grounds for disciplinary action, up to and including
                  termination and/or civil and criminal prosecution.
                  <br />
                </div>
              ) : null}
            </div>
          </button>
        </div>
      </div>
    </div>)
};

export default Login;
