import React, { useState, useEffect } from "react";
import { getTable } from "../../../../services/ReadyOneAPI";
import RingLoader from "react-spinners/RingLoader";
import styles from "./AppDrawer.module.css";
import { Header, AppStreamButton } from "../AppComponents";

const AppDrawer = () => {
  const [displayApps, setDisplayApps] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
      getTable("AppStreams").then((result) => {
        let apps = result.body.Items.filter(hidden);
        setDisplayApps(apps);
        setLoading(false);
        console.log(apps)
      });

  }, []);

  const hidden = (app) => {
    return app.category === "training";
  };


  return (
    <div className={styles.appHolder}>
      {loading ? (
        <RingLoader size={80} color={"#ffffff"} loading={loading} />
      ) : (
        <>
          <Header title="Training Applications">
          </Header>

          <CoreApps applications={displayApps} />
        </>
      )}
    </div>
  );
};

const CoreApps = ({ applications }) => {
  return (
    <div className={styles.core}>
      {applications.map((app) => (
          <AppStreamButton
            mode={app.mode}
            key={`${app.appName} ${app.title} ${app.mode} Main Column`}
            application={app}
            spinClass="spin"
          />
        ))}
    </div>
  );
};

export default AppDrawer;
